import { QuestionType } from '@prisma/client';
import { AnimatePresence, motion } from 'framer-motion';
import Container from 'lib/components/misc/Container';
import ErrorBoundary from 'lib/components/misc/ErrorBoundary';
import { ArrowDown } from 'lib/components/misc/icons';
import ChoicePartial from 'lib/components/question/ChoicePartial';
import ClosestPartial from 'lib/components/question/ClosestPartial';
import GeoPartial from 'lib/components/question/GeoPartial';
import OpenPartial from 'lib/components/question/OpenPartial';
import Alert from 'lib/components/ui/Alert';
import Button from 'lib/components/ui/Button';
import Header from 'lib/components/ui/Header';
import Headline from 'lib/components/ui/Headline';
import { MarkerProps } from 'lib/components/ui/Map';
import Paragraph from 'lib/components/ui/Paragraph';
import { calculateDistance } from 'lib/helper/data';
import { TimerProvider } from 'lib/hooks/timer';
import { useTranslation } from 'lib/translation';
import { type NextPage } from 'next';
import { useEffect, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import CountUp from 'react-countup';
import { GivenAnswer } from 'src/types';

import { FinishedPartial } from './game/[nanoId]';

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 1 } },
  exit: { opacity: 0 },
};

const Home: NextPage = () => {
  const [slotIndex, setSlotIndex] = useState(0);
  const [answers, setAnswers] = useState<GivenAnswer[]>([]);

  const handleShowAnswer = () => {
    const newAnswers = [...answers];

    if (!newAnswers[slotIndex]) {
      newAnswers[slotIndex] = {
        answer: '',
        points: 0,
      };

      setAnswers(newAnswers);

      return;
    }

    let points = 0;

    switch (dummyQuestions[slotIndex]?.questionType) {
      case QuestionType.Choice:
        if (dummyQuestions[slotIndex]?.content.answer === answers[slotIndex]?.answer) {
          points = 100;
        }
        break;
      case QuestionType.Open:
        if (
          (dummyQuestions[slotIndex]?.content.answer as string).toLowerCase() ===
          (answers[slotIndex]?.answer as string).toLowerCase()
        ) {
          points = 100;
        }
        break;
      case QuestionType.Closest:
        const correctAnswer = dummyQuestions[slotIndex]?.content.answer;
        const answer = answers[slotIndex]?.answer;

        if (typeof answer === 'number' && typeof correctAnswer === 'number') {
          if (answer >= correctAnswer * 0.8 && answer <= correctAnswer * 1.2) {
            points = 100;
          }
        }
        break;
      case QuestionType.Geo:
        const { lat, lng } = answers[slotIndex]?.answer as MarkerProps;

        if (!lat || !lng) return;

        const distance = calculateDistance(
          dummyQuestions[slotIndex]?.content.lat,
          dummyQuestions[slotIndex]?.content.lng,
          lat,
          lng
        );

        if (distance <= 1000000) {
          points = 100;
        }
    }

    newAnswers[slotIndex]!.points = points;

    setAnswers(newAnswers);
  };

  const resetQuiz = () => {
    setSlotIndex(0);
    setAnswers([]);
  };

  const players = [
    {
      player: {
        id: '1',
        name: 'Fox',
      },
      score: 500,
    },
    {
      player: {
        id: '2',
        name: 'Deer',
      },
      score: 100,
    },
    {
      player: {
        id: '3',
        name: 'Squirrel',
      },
      score: 0,
    },
    {
      player: {
        id: '3',
        name: 'Bear',
      },
      score: answers.reduce((acc, answer) => acc + (answer.points || 0), 0),
    },
  ];

  return (
    <ErrorBoundary>
      <TimerProvider timestamp={0} timeLimit={0} started={false}>
        <Header navigation />
        <div className="container mx-auto flex flex-col gap-x-8 gap-y-16 px-4 pt-8 pb-16 md:flex-row">
          <div className="col-span-2 self-center">
            <h1 className="mb-4 font-extrabold text-gray-900">
              <span className="block text-6xl">Unleash your inner quiz master</span>
              <span className="block text-3xl">and host unforgettable trivia nights with ease.</span>
            </h1>
            <Paragraph className="mb-8">
              Quizzlybear is the ultimate pub quiz app designed to cater to pub owners, organizations, party hosts, and
              anyone else who wants to create and host interactive quizzes effortlessly.
            </Paragraph>
            <Paragraph className="text-red-300">
              <Alert
                type="warning"
                title="Warning"
                description={
                  <>
                    This is a very early version of Quizzlybear. Things might break, and there are still a lot of
                    features missing. Please bear with me!
                  </>
                }
              />
            </Paragraph>
            <Button className="mt-4" as="link" href="/signup" color="primary">
              Get Started!
            </Button>
          </div>
          <div className="flex shrink-0 flex-col items-center gap-4">
            <div className="">
              <h2 className="mb-4 flex items-center gap-2 text-xl font-extrabold text-primary-600">
                <span>Try it out!</span>
                <ArrowDown />
              </h2>
            </div>
            <div className="relative inline-block aspect-[1/2] w-72 place-self-center rounded-[1.8rem] border-4 border-primary-500 before:absolute before:top-20 before:-left-1.5 before:h-10 before:w-1 before:rounded-l before:bg-primary-500 after:absolute after:top-32 after:-left-1.5 after:h-10 after:w-1 after:rounded-l after:bg-primary-500">
              <div className="absolute inset-0 overflow-hidden rounded-3xl border-8 border-gray-900 before:absolute before:left-1/2 before:-top-0.5 before:h-5 before:w-1/2 before:-translate-x-1/2 before:transform before:rounded-b-xl before:bg-gray-900 after:absolute after:top-24 after:-right-3.5 after:h-14 after:w-1 after:rounded-r after:bg-primary-500">
                <div className="bg-bear-blue-400 absolute inset-0 -mx-[2.5rem] -my-[5.5rem] scale-75 overflow-hidden rounded-b-2xl">
                  <AnimatePresence mode="wait" initial={false}>
                    {slotIndex < 4 && (
                      <motion.div key="running" {...motionProps} className="h-full">
                        <RunningPartial slotIndex={slotIndex} answers={answers} setAnswers={setAnswers} />
                      </motion.div>
                    )}
                    {slotIndex === 4 && (
                      <motion.div key="finished" {...motionProps}>
                        <FinishedPartial players={players.sort((a, b) => b.score - a.score)} />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
            <div>
              {slotIndex < 4 && answers[slotIndex]?.points === undefined && (
                <Button color="primary" onClick={handleShowAnswer} compact>
                  Show answer
                </Button>
              )}
              {slotIndex < 4 && answers[slotIndex]?.points !== undefined && (
                <Button color="primary" onClick={() => setSlotIndex(slotIndex + 1)} compact>
                  Next
                </Button>
              )}
              {slotIndex === 4 && (
                <Button color="primary" onClick={resetQuiz} compact>
                  Reset
                </Button>
              )}
            </div>
          </div>
        </div>
        {/*
        <section className="container mx-auto px-4 py-8">
          <Headline size="h2">About Quizzlybear</Headline>
          <Paragraph>
            Quizzlybear is the ultimate pub quiz web application designed to cater to pub owners, organizations, and
            party hosts who want to create and host interactive quizzes effortlessly. Our platform empowers you to craft
            your own questions, curate engaging quiz experiences, and entertain your participants with ease.
          </Paragraph>
          <Headline size="h2">Unleash Your Inner Quiz Master</Headline>
          <Paragraph>
            With Quizzlybear, you have the freedom to unleash your creativity and tailor quizzes to suit your
            event&apos;s theme and audience. Create various question types, including classic multiple-choice questions,
            brain-teasing guessing questions, thought-provoking open-ended questions, interactive geo-guessing questions
            with maps, and mind-boggling scrambled word challenges. The possibilities are endless!
          </Paragraph>
          <Headline size="h2">Simplified Participation</Headline>
          <Paragraph>
            We believe in making things easy for everyone involved. That&apos;s why Quizzlybear ensures a seamless
            experience for both hosts and players. Participants can join your quiz by simply scanning a QR code or
            opening the game link in their browser. No app downloads required! This user-friendly approach allows your
            audience to engage effortlessly, guaranteeing maximum participation and excitement.
          </Paragraph>
          <Headline size="h2">Designed for Hosts, Loved by Players</Headline>
          <Paragraph>
            As a host, you can effortlessly create and manage quizzes, track scores, and generate insightful reports to
            evaluate your participants&apos; performance. Quizzlybear equips you with the tools you need to host
            unforgettable quiz nights, corporate events, or private gatherings.
          </Paragraph>
          <Headline size="h2">Free Forever Option</Headline>
        <Paragraph>
          We believe in providing accessible and inclusive quiz experiences. Quizzlybear is currently free, and we
          commit to always offering a free option in the future. You can enjoy the core features without any
          limitations, ensuring that the joy of trivia is accessible to all.
        </Paragraph>
          <Headline size="h2">Join the Quizzlybear Community</Headline>
          <Paragraph>
            Quizzlybear is more than just a quiz platform; it&apos;s a vibrant community of passionate quiz enthusiasts.
            Connect with like-minded individuals, exchange quiz ideas, and discover new ways to engage your audience.
            Join us on social media and become a part of the Quizzlybear family!
          </Paragraph>
          <Headline size="h2">Ready to Get Started?</Headline>
          <Paragraph>
            Creating an account is quick and easy! Register today to unlock the full potential of Quizzlybear and embark
            on a journey of endless quizzes, laughter, and knowledge.
          </Paragraph>
          <Headline size="h2">Remember, Bear in Mind</Headline>
          <Paragraph>
            At Quizzlybear, we take pride in our bear-y fun approach to quizzes. While we can&apos;t bear to overload
            you with too many puns, we promise to sprinkle in just the right amount of bear-inspired humor throughout
            your experience.
          </Paragraph>
          Thank you for choosing Quizzlybear, where hosting quizzes is as delightful as a bear hug!
        </section>
        */}
      </TimerProvider>
    </ErrorBoundary>
  );
};

const dummyQuestions = [
  {
    slotId: '1',
    questionType: QuestionType.Choice,
    question: 'What is the capital of Germany?',
    content: {
      options: [
        {
          value: 'Berlin',
        },
        {
          value: 'Hamburg',
        },
        {
          value: 'Munich',
        },
        {
          value: 'Cologne',
        },
      ],
      answer: 0,
    },
  },
  {
    slotId: '2',
    questionType: QuestionType.Open,
    question: 'What was the name of the Turkish city Istanbul until 1923?',
    content: {
      answer: 'Constantinople',
    },
  },
  {
    slotId: '3',
    questionType: QuestionType.Closest,
    question: 'How much does a baby panda weigh at birth?',
    content: {
      answer: 130,
      unit: 'g',
    },
  },
  {
    slotId: '4',
    questionType: QuestionType.Geo,
    question: 'Where is the Eiffel Tower located?',
    content: {
      lat: 48.8583701,
      lng: 2.2922926,
    },
  },
];

const RunningPartial = ({
  slotIndex,
  answers,
  setAnswers,
}: {
  slotIndex: number;
  answers: GivenAnswer[];
  setAnswers: (answers: GivenAnswer[]) => void;
}) => {
  const [score, setScore] = useState<number | null>(null);
  const [lastScore, setLastScore] = useState<number>(0);
  const [confetti, setConfetti] = useState(0);
  const { translate } = useTranslation();

  useEffect(() => {
    const newScore = Object.values(answers).reduce((acc, answer) => acc + (answer.points || 0), 0);
    setScore(newScore);

    setTimeout(() => {
      setLastScore(newScore);

      if (score !== null && newScore > score) {
        setConfetti(confetti + 1);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const handleSubmitAnswer = (answer: GivenAnswer['answer']) => {
    console.log(answer);

    const newAnswers = [...answers];
    newAnswers[slotIndex] = {
      answer,
    };

    setAnswers(newAnswers);
  };

  return (
    <div className="flex h-full flex-col overflow-hidden pt-8 pb-4 transition-colors duration-1000">
      <Container className="relative flex grow flex-col px-4" mobile>
        <ReactCanvasConfetti
          className="pointer-events-none absolute -top-8 -right-16 z-0 h-72 w-72"
          colors={['#44aac4', '#44aac4', '#44aac4', '#aeaeae', '#dcdcdc', '#696969']}
          particleCount={25}
          gravity={0.2}
          spread={40}
          angle={230}
          scalar={1}
          width={288}
          height={288}
          startVelocity={12}
          decay={0.93}
          drift={-0.5}
          origin={{ x: 1, y: -0.2 }}
          fire={confetti}
        />
        <div className="mb-4 flex justify-between text-xl font-black transition-colors duration-1000">
          <div>
            {slotIndex + 1} / {dummyQuestions.length}
          </div>

          <div className="tabular-nums">
            {translate('score')}: <CountUp start={lastScore} end={score || 0} duration={1} useEasing />
          </div>
        </div>
        <AnimatePresence mode="wait" initial={false}>
          <motion.div key={dummyQuestions[slotIndex]?.slotId} className="flex grow flex-col" {...motionProps}>
            <Headline className="transition-colors duration-1000" size="h2">
              {dummyQuestions[slotIndex]?.question}
            </Headline>

            <div className="flex grow flex-col justify-end">
              {/* <InteractiveFrame {...game} /> */}
              {dummyQuestions[slotIndex]?.questionType === QuestionType.Choice && (
                <ChoicePartial
                  {...dummyQuestions[slotIndex]!}
                  givenAnswer={answers[slotIndex]}
                  withAnswer={answers[slotIndex]?.points !== undefined}
                  onSubmit={handleSubmitAnswer}
                />
              )}

              {dummyQuestions[slotIndex]?.questionType === QuestionType.Open && (
                <OpenPartial
                  {...dummyQuestions[slotIndex]!}
                  givenAnswer={answers[slotIndex]}
                  withAnswer={answers[slotIndex]?.points !== undefined}
                  onSubmit={handleSubmitAnswer}
                />
              )}

              {dummyQuestions[slotIndex]?.questionType === QuestionType.Closest && (
                <ClosestPartial
                  {...dummyQuestions[slotIndex]!}
                  givenAnswer={answers[slotIndex]}
                  withAnswer={answers[slotIndex]?.points !== undefined}
                  onSubmit={handleSubmitAnswer}
                />
              )}

              {dummyQuestions[slotIndex]?.questionType === QuestionType.Geo && (
                <GeoPartial
                  {...dummyQuestions[slotIndex]!}
                  givenAnswer={answers[slotIndex]}
                  withAnswer={answers[slotIndex]?.points !== undefined}
                  onSubmit={handleSubmitAnswer}
                />
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      </Container>
    </div>
  );
};

export default Home;
