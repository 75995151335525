import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'lib/translation';
import Link from 'next/link';
import { useState } from 'react';

import { LogIn01 } from '../misc/icons';
import Button from './Button';
import Logo from './Logo';

type HeaderProps = {
  navigation?: boolean;
};

const navItems = [
  {
    label: 'page.features',
    href: '/features',
  },
  {
    label: 'page.pricing',
    href: '/pricing',
  },
  {
    label: 'page.about',
    href: '/about',
  },
];

// TODO: optimize for mobile

const Header = ({ navigation }: HeaderProps) => {
  const { translate } = useTranslation();

  return (
    <header className={classNames('flex h-[5.5rem] items-center justify-between p-4')}>
      <Link href="/">
        <Logo className="w-48 md:w-60" animated />
      </Link>
      {navigation && (
        <>
          {/* <nav className="flex items-center">
            <ul className="flex gap-8">
              {navItems.map((item) => (
                <NavItem key={item.label} {...item} />
              ))}
            </ul>
          </nav> */}
          <div className="flex items-center gap-2">
            <Button as="link" href="/signin" color="white">
              <span className="hidden sm:block">{translate('form.signin')}</span>
              <LogIn01 className="block sm:hidden text-2xl" />
            </Button>
            <Button className="hidden sm:block" as="link" href="/signup" hideLabelUntil="sm">
              {translate('form.signup')}
            </Button>
          </div>
        </>
      )}
    </header>
  );
};

type NavItemProps = {
  label: string;
  href: string;
};

const NavItem = ({ label, href }: NavItemProps) => {
  const { translate } = useTranslation();

  return (
    <li className="text-lg font-extrabold">
      <Link href={href}>{translate(label)}</Link>
    </li>
  );
};

const BurgerButton = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);

  return (
    <button className="relative h-12 w-12 pt-2 pb-3" onClick={() => setNavIsOpen((v) => !v)}>
      <motion.div
        className={classNames('absolute left-2 right-2 h-1 rounded-full bg-gray-900 transition-transform', {
          '-translate-y-2.5': !navIsOpen,
          'translate-y-0 -rotate-45': navIsOpen,
        })}
      />
      <motion.div
        className={classNames('absolute left-2 right-2 h-1 rounded-full bg-gray-900 transition-opacity', {
          'opacity-100': !navIsOpen,
          'opacity-0': navIsOpen,
        })}
      />
      <motion.div
        className={classNames('absolute left-2 right-2 h-1 rounded-full bg-gray-900 transition-transform', {
          'translate-y-2.5': !navIsOpen,
          'translate-y-0 rotate-45': navIsOpen,
        })}
      />
    </button>
  );
};

export default Header;
